<template>
  <div class="login-page">
    <el-image
      @contextmenu="handleMouse"
      style="width: 100%; height: 100%"
      :src="url"
      :fit="'cover'"
    ></el-image>
    <div class="login-con">
      <img
        @click="backHome()"
        src="@/assets/images/logo-white.png"
        class="logo"
      />
      <register v-if="active === 'register'" @click="handleClick"></register>
      <registered
        v-if="active === 'registered'"
        @click="handleClick"
      ></registered>
      <login v-if="active === 'login'" @click="handleClick"></login>
      <retrievePwd
        v-if="active === 'retrievePwd'"
        @click="handleClick"
        @submit="handelSubmit"
      ></retrievePwd>
      <setPwd
        v-if="active === 'setPwd'"
        @click="handleClick"
        :value="accountData"
      ></setPwd>
      <qr-code-login
        v-if="active === 'qrcode'"
        @click="handleClick"
      ></qr-code-login>
    </div>
  </div>
</template>

<script>
import register from "./register";
import registered from "./registered";
import login from "./login";
import retrievePwd from "./retrievePwd";
import setPwd from "./setPwd";
import QrCodeLogin from "./qrcodeLogin";
export default {
  name: "index",
  components: { register, registered, login, retrievePwd, setPwd, QrCodeLogin },
  data() {
    return {
      url: require("../../assets/images/login-bg.jpg"),
      active: "login",
      accountData: {}
    };
  },
  mounted() {
    const type = this.$route.params.type;
    this.active = type ?? "login";
  },
  methods: {
    handleClick(val) {
      console.log(val, "val");
      this.active = val;
    },
    handleMouse(e) {
      e.preventDefault();
    },
    backHome() {
      this.$router.push({ name: "home" });
    },
    handelSubmit(val) {
      this.active = "setPwd";
      this.accountData = val;
      console.log(123, val);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.login-page {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .login-con {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
    .logo {
      width: 323px;
      margin-bottom: 73px;
    }
    .login-item {
      width: 463px;
      background: rgba(0, 0, 0, 0.65);
      border-radius: 7px;
      padding-bottom: 30px;
      .login-form {
        padding: 0 40px;
        .el-form {
          width: 322px;
          margin: 0 auto;
        }
        .login-text {
          margin-top: 14px;
          .text {
            display: inline-block;
            margin-left: 10px;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            span {
              margin: 0 5px;
              cursor: pointer;
            }
          }
        }
        .el-button--primary {
          width: 100%;
          border: none;
          border-radius: 50px;
          font-size: 17px !important;
          font-family: PingFang SC !important;
          font-weight: 400 !important;
          color: #ffffff;
          background-color: #ba001b;
        }
        .el-checkbox {
          .el-checkbox__inner {
            background-color: #ffffff;
            border-color: #ffffff;
            &:hover {
              border: 1px solid #ffffff;
            }
            &::after {
              border: 2px solid #ff7067;
              border-left: 0;
              border-top: 0;
            }
          }
        }
        .el-form-item {
          margin-bottom: 33px;
          .el-input__inner,
          .el-textarea__inner {
            background: transparent;
            border: 1px solid #a0a0a0;
            color: #ffffff;
          }
          .el-input__inner {
            height: 34px;
            line-height: 34px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            background: rgba(0, 0, 0, 0);
            border: 1px solid #d3d3d2;
          }
          .el-input__count {
            background: transparent;
            height: 28px;
            right: 5px;
          }
          .el-input-group__append {
            background: transparent;
            border: 1px solid #d3d3d2;
            border-left: 0;
            color: #ff7067;
            font-size: 12px;
            position: relative;
            left: -1px;
            span {
              font-weight: 400;
            }
          }
        }
        .code-input {
          .el-input__inner {
            border-right: 0;
          }
        }
      }
      .login-other {
        margin-top: 50px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
