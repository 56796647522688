<template>
  <div class="login-item">
    <img src="@/assets/images/qr.png" class="qr-img" @click="handleWechat" />
    <!-- <img
      src="@/assets/images/qr.png"
      class="qr-img"
      @click="handleClick('qrcode')"
    /> -->
    <ul class="tabs">
      <li v-for="item in tabs" :key="item.value" @click="clickTab(item.value)">
        {{ item.label }}
        <div class="line" v-if="activeTab === item.value"></div>
      </li>
    </ul>
    <div class="login-form">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder="请输入手机号/邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" v-if="activeTab === 'message'">
          <el-input
            v-model="ruleForm.code"
            placeholder="验证码"
            class="code-input"
          >
            <el-button slot="append" @click="getCode" v-if="!getCodeing">{{
              count === 0 ? "重新发送验证码" : "获取验证码"
            }}</el-button>
            <el-button slot="append" v-else
              >（{{ count }}）s 已发送验证码至{{ accountType }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          class="password"
          v-if="activeTab === 'password'"
        >
          <el-input
            v-model="ruleForm.password"
            placeholder="请输入密码"
            autocomplete="new-password"
            :type="inputType"
          ></el-input>
          <img :src="eye[inputType]" @click="clickEye" class="eye" />
        </el-form-item>
        <JcRange
          style="margin-bottom: 16px;"
          status="status"
          :successFun="onMpanelSuccess"
          :errorFun="onMpanelError"
          ref="jcRangeNode"
        ></JcRange>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          class="submit-btn"
          >登录</el-button
        >
        <div class="login-text">
          <p class="text">
            登录即代表同意<span class="text-style" @click="handleAgreement"
              >《用户协议》</span
            >
          </p>
        </div>
      </el-form>
    </div>
    <div class="login-other">
      <p @click="handleClick('retrievePwd')">忘记密码</p>
      |
      <p @click="handleClick('register')">注册</p>
    </div>
  </div>
</template>

<script>
import JcRange from "@/components/JcRange.vue";
import { validate } from "@/utils/validity.js";
export default {
  name: "index",
  components: { JcRange },
  data() {
    return {
      inputType: "password",
      eye: {
        password: require("@/assets/images/eye-hide.png"),
        text: require("@/assets/images/eye-see.png")
      },
      accountType: "手机号",
      getCodeing: false,
      count: 60,
      ruleForm: {
        account: "",
        code: "",
        password: "",
        type: 1 //1账号密码登陆2验证码登陆3扫码登录
      },
      tabs: [
        {
          label: "短信登录",
          value: "message"
        },
        {
          label: "密码登录",
          value: "password"
        }
      ],
      activeTab: "message",
      isSuccess: false,
      rules: {
        account: v => {
          if (!v) return { message: "请输入手机号或邮箱" };
          if (validate.phoneNumber(v)) return { accountType: "手机号" };
          if (validate.mailbox(v)) return { accountType: "邮箱" };
          return { message: "请输入正确的手机号或邮箱" };
        },
        code: v => {
          if (!v) return "请输入验证码";
        },
        password: v => {
          if (!v) return "请输入密码";
        }
      }
    };
  },
  methods: {
    async submitForm() {
      let { account, code, password } = this.ruleForm;
      const { message, accountType } = this.rules.account(account);
      if (message) return this.$message({ message, type: "warning" });
      if (accountType) this.accountType = accountType;
      if (this.activeTab === "message") {
        const msg = this.rules.code(code);
        this.ruleForm.type = 2;
        if (msg) return this.$message({ message: msg, type: "warning" });
      }
      if (this.activeTab === "password") {
        const msg = this.rules.password(password);
        this.ruleForm.type = 1;
        if (msg) return this.$message({ message: msg, type: "warning" });
      }
      if (!this.isSuccess)
        return this.$message({
          message: "请按住滑块，拖至最右端",
          type: "warning"
        });
      // 调用登录接口,成功后跳转首页
      const { data } = await this.$api.getLogin(this.ruleForm);
      if (data.code === 1) {
        //此处保存用户相关信息
        localStorage.setItem("user_info", JSON.stringify(data.data));
        localStorage.setItem("user_id", data.data.id);
        this.$router.push({ name: "home" });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
    },
    clickEye() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    async getCode() {
      const { account } = this.ruleForm;
      const { message, accountType } = this.rules.account(account);
      if (message) return this.$message({ message, type: "warning" });
      if (accountType) this.accountType = accountType;
      //调取获取验证码接口
      const { data } = await this.$api.getCode({ account, code_type: 2 });
      if (data.code === 1) {
        this.getCodeing = true;
        const timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            clearInterval(timer);
            this.getCodeing = false;
          }
        }, 1000);
      } else {
        this.getCodeing = false;
      }
    },
    onMpanelSuccess() {
      console.log("验证成功");
      this.isSuccess = true;
    },
    onMpanelError() {
      console.log("验证失败");
      this.isSuccess = false;
    },
    clickTab(val) {
      this.activeTab = val;
      this.$refs.ruleForm.resetFields();
      this.$refs.jcRangeNode.restore();
      this.isSuccess = false;
    },
    handleClick(val) {
      this.$emit("click", val);
    },
    handleAgreement() {
      this.$router.push({ name: "UserAgreement" });
    },
    handleWechat() {
      //加上这个便可以跳转啦
      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxfa693e73400bb470&redirect_uri=http%3A%2F%2Fgjdl960.com%2F%23%2Ftransfer&response_type=code&scope=snsapi_login&state=93e612f9fcf07291f32caa4807eaf77b#wechat_redirect";
    }
  }
};
</script>

<style lang="scss">
.login-item {
  position: relative;
  .qr-img {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 66px;
    height: 66px;
    cursor: pointer;
  }
  .tabs {
    display: flex;
    margin-bottom: 45px;
    padding-top: 60px;
    margin-left: 69px;
    li {
      margin-right: 52px;
      padding: 5px 0;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      height: 26px;
      .line {
        width: 36px;
        height: 4px;
        background: #e60021;
        border-radius: 10px;
        margin-top: 10px;
        position: relative;
        left: 18px;
        &:after {
          content: " ";
          position: absolute;
          right: -8px;
          top: 0;
          width: 4px;
          height: 4px;
          background: #e60021;
          border-radius: 4px;
        }
      }
    }
  }
  .login-form {
    .submit-btn {
      margin-top: 20px;
    }
    .login-text {
      .text-style {
        color: #69cdff;
      }
    }
    .el-form-item__content {
      .el-input__inner {
        outline: none;
      }
    }
  }
  .login-other {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #ffffff;
    p {
      margin: 0 10px;
    }
  }
  .password {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .eye {
        width: 20px;
        position: absolute;
        right: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
