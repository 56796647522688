<template>
  <div class="login-item retrieve-pwd">
    <div class="title">找回密码</div>
    <div class="login-form">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder="请输入手机号\邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="ruleForm.code"
            placeholder="验证码"
            class="code-input"
          >
            <el-button slot="append" @click="getCode" v-if="!getCodeing">{{
              count === 0 ? "重新发送验证码" : "获取验证码"
            }}</el-button>
            <el-button slot="append" v-else
              >（{{ count }}）s 已发送验证码至{{ accountType }}</el-button
            >
          </el-input>
        </el-form-item>
        <JcRange
          style="margin-bottom: 16px;"
          status="status"
          :successFun="onMpanelSuccess"
          :errorFun="onMpanelError"
        ></JcRange>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          class="submit-btn"
          >下一步</el-button
        >
      </el-form>
    </div>
    <div class="login-other">
      <p @click="handleClick('login')">登录</p>
    </div>
  </div>
</template>

<script>
import JcRange from "@/components/JcRange.vue";
import { validate } from "@/utils/validity.js";
export default {
  name: "index",
  components: { JcRange },
  data() {
    return {
      status: false,
      ruleForm: {
        account: "",
        code: ""
      },
      accountType: "手机号",
      getCodeing: false,
      count: 60,
      rules: {
        account: v => {
          if (!v) return { message: "请输入手机号或邮箱" };
          if (validate.phoneNumber(v)) return { type: "手机号" };
          if (validate.mailbox(v)) return { type: "邮箱" };
          return { message: "请输入正确的手机号或邮箱" };
        },
        code: v => {
          if (!v) return "请输入验证码";
        }
      },
      isSuccess: false
    };
  },
  methods: {
    submitForm() {
      const { account, code } = this.ruleForm;
      const { message, type } = this.rules.account(account);
      if (message) return this.$message({ message, type: "warning" });
      if (type) this.accountType = type;
      const msg = this.rules.code(code);
      if (msg) return this.$message({ msg, type: "warning" });
      if (!this.isSuccess)
        return this.$message({
          message: "请按住滑块，拖至最右端",
          type: "warning"
        });
      // 跳转下一步，传递参数
      this.$emit("submit", this.ruleForm);
    },
    onMpanelSuccess() {
      this.isSuccess = true;
      console.log("验证成功");
    },
    onMpanelError() {
      console.log("验证失败");
    },
    handleClick(val) {
      this.$emit("click", val);
    },
    async getCode() {
      const { account } = this.ruleForm;
      const { message, type } = this.rules.account(account);
      if (message) return this.$message({ message, type: "warning" });
      if (type) this.accountType = type;
      //调取获取验证码接口
      const { data } = await this.$api.getCode({ account, code_type: 3 });
      if (data.code === 1) {
        this.getCodeing = true;
        const timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            clearInterval(timer);
            this.getCodeing = false;
          }
        }, 1000);
      } else {
        this.getCodeing = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.retrieve-pwd {
  padding-top: 50px;
  .title {
    font-size: 27px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 56px 0;
  }
}
.login-item {
  .login-form {
    .submit-btn {
      margin-top: 20px;
    }
    .login-text {
      .text-style {
        color: #69cdff;
      }
    }
  }
}
</style>
