<template>
  <div class="login-item">
    <div class="login-nav">
      <span @click="backHome()">返回中国国家地理频道</span>
      <span @click="handleClick('login')">已有帐号登录</span>
    </div>
    <div class="title">注册</div>
    <div class="login-form">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="nickname">
          <el-input
            v-model="ruleForm.nickname"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="ruleForm.code"
            placeholder="请输入验证码"
            class="code-input"
          >
            <el-button slot="append" @click="getCode" v-if="!getCodeing">{{
              count === 0 ? "重新发送验证码" : "获取验证码"
            }}</el-button>
            <el-button slot="append" v-else
              >({{ count }})s 已发送验证码至手机</el-button
            >
          </el-input>
        </el-form-item>
        <JcRange
          style="margin-bottom: 16px;"
          status="status"
          :successFun="onMpanelSuccess"
          :errorFun="onMpanelError"
        ></JcRange>
        <el-form-item prop="password" class="password">
          <el-input
            v-model="ruleForm.password"
            placeholder="请设置8-20位密码"
            autocomplete="new-password"
            :type="inputType"
          ></el-input>
          <img :src="eye[inputType]" @click="clickEye" class="eye" />
        </el-form-item>
        <div class="login-text">
          <el-checkbox v-model="ruleForm.checked"></el-checkbox>
          <p class="text">
            我已阅读并接受<span class="text-span" @click="handleAgreement"
              >用户协议</span
            >和<span class="text-span" @click="handlePrivacy">隐私政策</span>
          </p>
        </div>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          class="submit-btn"
          >注册</el-button
        >
      </el-form>
    </div>
    <div class="login-other" @click="handleWechat">
      <span>使用社交账号注册</span>
      <img src="@/assets/images/WeChat-icon.png" />
    </div>
  </div>
</template>

<script>
import JcRange from "@/components/JcRange.vue";
import { validate } from "@/utils/validity.js";
export default {
  name: "index",
  components: { JcRange },
  data() {
    return {
      status: false,
      ruleForm: {
        nickname: "",
        email: "",
        mobile: "",
        code: "",
        password: "",
        checked: true
      },
      eye: {
        password: require("@/assets/images/eye-hide.png"),
        text: require("@/assets/images/eye-see.png")
      },
      getCodeing: false,
      count: 60,
      inputType: "password",
      rules: {
        nickname: v => {
          if (!v) return "请输入昵称";
        },
        email: v => {
          if (!v) return "请输入邮箱";
          if (!validate.mailbox(v)) return "请输入正确的邮箱";
        },
        mobile: v => {
          if (!v) return "请输入手机号";
          if (!validate.phoneNumber(v)) return "请输入正确的手机号";
        },
        code: v => {
          if (!v) return "请输入验证码";
        },
        password: v => {
          if (!v || v.length < 8 || v.length > 20) return "请设置8-20位密码";
        },
        checked: v => {
          if (!v) return "请阅读并接受用户协议和隐私政策";
        }
      }
    };
  },
  methods: {
    async submitForm() {
      const form = this.ruleForm;
      for (const key in form) {
        const validator = this.rules[key];
        if (validator) {
          const message = validator(form[key]);
          if (message) {
            return this.$message({ message, type: "warning" });
          }
        }
      }
      if (!this.status)
        return this.$message({
          message: "请按住滑块，拖至最右端",
          type: "warning"
        });
      // 调注册接口，成功后跳转登陆页面
      const { data } = await this.$api.getRegister(this.ruleForm);
      if (data.code === 1) {
        this.handleClick("login");
      } else {
        if (data.msg == "该手机号已注册") {
          this.handleClick("registered");
        } else {
          this.$message({
            message: data.msg,
            type: "warning"
          });
        }
      }
    },
    clickEye() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    onMpanelSuccess() {
      this.status = true;
      console.log("验证成功");
    },
    onMpanelError() {
      console.log("验证失败");
    },
    handleClick(val) {
      this.$emit("click", val);
    },
    handleWechat() {
      //加上这个便可以跳转啦
      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxfa693e73400bb470&redirect_uri=http%3A%2F%2Fgjdl960.com%2F%23%2Ftransfer&response_type=code&scope=snsapi_login&state=93e612f9fcf07291f32caa4807eaf77b#wechat_redirect";
    },
    backHome() {
      this.$router.push({ name: "home" });
    },
    handleAgreement() {
      this.$router.push({ name: "UserAgreement" });
    },
    handlePrivacy() {
      this.$router.push({ name: "PrivacyPolicy" });
    },
    async getCode() {
      const { mobile } = this.ruleForm;
      const message = this.rules.mobile(mobile);
      if (message) return this.$message({ message, type: "warning" });
      //调取获取验证码接口
      const { data } = await this.$api.getCode({ mobile, code_type: 1 });
      if (data.code === 1) {
        this.getCodeing = true;
        const timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            clearInterval(timer);
            this.getCodeing = false;
          }
        }, 1000);
      } else {
        this.getCodeing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-item {
  .login-nav {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .title {
    font-size: 27px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 56px 0;
  }
  .login-form {
    .el-form {
      width: 322px;
      margin: 0 auto;
      .submit-btn {
        margin-top: 24px;
      }
      .el-form-item {
        margin-bottom: 21px !important;
        .el-input__inner,
        .el-textarea__inner {
          background: transparent;
          border: 1px solid #a0a0a0;
          color: #ffffff;
        }
        .el-input__inner {
          height: 34px;
          line-height: 34px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          background: rgba(0, 0, 0, 0);
          border: 1px solid #d3d3d2;
        }
        .el-input__count {
          background: transparent;
          height: 28px;
          right: 5px;
        }
        .el-input-group__append {
          background: transparent;
          border: 1px solid #d3d3d2;
          border-left: 0;
          color: #ff7067;
          font-size: 12px;
          span {
            font-weight: 400;
          }
        }
      }
    }
    .login-text {
      margin-top: 29px !important;
      .text-span {
        color: #ff7067;
      }
    }
  }
  .login-other {
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
    }
    img {
      width: 26px;
      margin-left: 5px;
    }
  }
  .password {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .eye {
        width: 20px;
        position: absolute;
        right: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
