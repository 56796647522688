<template>
  <div class="login-item retrieve-pwd">
    <div class="title">设置密码</div>
    <div class="account">账号:{{ value.account }}</div>
    <div class="login-form">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="password" class="password">
          <el-input
            v-model="ruleForm.password"
            placeholder="设置新密码"
            autocomplete="new-password"
            :type="inputType"
          ></el-input>
          <img :src="eye[inputType]" @click="clickEye" class="eye" />
        </el-form-item>
        <el-form-item prop="againPassword" class="password">
          <el-input
            v-model="ruleForm.againPassword"
            placeholder="请再输入确认新密码"
            autocomplete="new-password"
            :type="againInputType"
          ></el-input>
          <img :src="eye[againInputType]" @click="clickAgainEye" class="eye" />
        </el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          class="submit-btn"
          >确认</el-button
        >
      </el-form>
    </div>
    <div class="login-other">
      <p @click="handleClick('login')">遇到问题</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      ruleForm: {
        password: "",
        againPassword: ""
      },
      eye: {
        password: require("@/assets/images/eye-hide.png"),
        text: require("@/assets/images/eye-see.png")
      },
      inputType: "password",
      againInputType: "password",
      rules: {
        password: v => {
          if (!v) return "请设置新密码";
          if (v.length < 8 || v.length > 20) return "请设置8-20位密码";
        },
        againPassword: v => {
          if (!v) return "请再输入确认新密码";
        }
      }
    };
  },
  props: {
    value: {
      type: Object
    }
  },
  // mounted() {
  //   console.log(999999, this.value);
  //   this.account = this.value.account;
  //   this.code = this.value.code;
  // },
  methods: {
    async submitForm() {
      const { password, againPassword } = this.ruleForm;
      const message = this.rules.password(password);
      if (message) return this.$message({ message, type: "warning" });
      const msg = this.rules.againPassword(againPassword);
      if (msg) return this.$message({ message: msg, type: "warning" });
      // 调用修改密码接口 ， props的value值是传过来的账号信息
      const { data } = await this.$api.getChangePwd({
        account: this.value.account,
        code: this.value.code,
        password
      });
      if (data.code === 1) {
        this.$confirm("密码修改成功，请重新登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success"
        })
          .then(() => {
            this.handleClick("login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message({ message: data.msg, type: "warning" });
      }
    },
    clickEye() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    clickAgainEye() {
      this.againInputType =
        this.againInputType === "password" ? "text" : "password";
    },
    handleClick(val) {
      this.$emit("click", val);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.retrieve-pwd {
  padding-top: 50px;
}
.login-item {
  .account {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 300;
    margin: 53px 0 44px !important;
  }
  .login-other {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #ffffff;
    margin-top: 81px !important;
    p {
      margin: 0 10px;
    }
  }
  .login-form {
    .submit-btn {
      margin-top: 20px;
    }
    .login-text {
      .text-style {
        color: #69cdff;
      }
    }
    .password {
      .el-form-item__content {
        display: flex;
        align-items: center;
        .eye {
          width: 20px;
          position: absolute;
          right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
