<template>
  <div class="login-item retrieve-pwd">
    <div class="title">微信二维码登录</div>
    <div class="login-form">
      <div class="login-qr"></div>
      <p class="login-qr-text">请使用微信扫描二维码登陆</p>
      <p class="login-qr-text">“中国国家地理·频道”</p>
    </div>
    <div class="login-other">
      <p @click="handleClick('login')">密码登录</p>
      |
      <p @click="handleClick('register')">注册</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  methods: {
    handleClick(val) {
      this.$emit("click", val);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.retrieve-pwd {
  padding-top: 50px;
}
.login-item {
  .login-form {
    .login-qr {
      width: 198px;
      height: 198px;
      margin: 0 auto;
      background: #000000;
      margin-bottom: 16px;
    }
    .login-qr-text {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
