export const telOrMail = val => {
  if (!val) {
    return "请输入手机号/邮箱";
  }
};

export const validate = {
  mailbox: v =>
    /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v),
  phoneNumber: tel => /^1[3456789]\d{9}$/.test(tel)
};
