<template>
  <div class="login-item">
    <div class="login-nav">
      <span @click="backHome()">返回中国国家地理频道</span>
      <span @click="handleClick('login')">已有帐号登录</span>
    </div>
    <div class="title">
      <img src="@/assets/images/registered-icon.png" />
      <span>该手机号已被注册!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  methods: {
    handleClick(val) {
      this.$emit("click", val);
    },
    backHome() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-item {
  height: 157px;
  .login-nav {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .title {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    img {
      width: 44px;
      height: 44px;
      margin-right: 15px;
    }
    span {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
    }
  }
}
</style>
